export const AreasOfInterestsEditor = () => import('../../components/AreasOfInterestsEditor.vue' /* webpackChunkName: "components/areas-of-interests-editor" */).then(c => wrapFunctional(c.default || c))
export const AttachmentPreview = () => import('../../components/AttachmentPreview.vue' /* webpackChunkName: "components/attachment-preview" */).then(c => wrapFunctional(c.default || c))
export const ChargingFeeEditor = () => import('../../components/ChargingFeeEditor.vue' /* webpackChunkName: "components/charging-fee-editor" */).then(c => wrapFunctional(c.default || c))
export const CollegeCardUploader = () => import('../../components/CollegeCardUploader.vue' /* webpackChunkName: "components/college-card-uploader" */).then(c => wrapFunctional(c.default || c))
export const Cookies = () => import('../../components/Cookies.vue' /* webpackChunkName: "components/cookies" */).then(c => wrapFunctional(c.default || c))
export const CustomerCaseCard = () => import('../../components/CustomerCaseCard.vue' /* webpackChunkName: "components/customer-case-card" */).then(c => wrapFunctional(c.default || c))
export const CustomerShareDoctor = () => import('../../components/CustomerShareDoctor.vue' /* webpackChunkName: "components/customer-share-doctor" */).then(c => wrapFunctional(c.default || c))
export const Datepicker = () => import('../../components/Datepicker.vue' /* webpackChunkName: "components/datepicker" */).then(c => wrapFunctional(c.default || c))
export const DoctorCard = () => import('../../components/DoctorCard.vue' /* webpackChunkName: "components/doctor-card" */).then(c => wrapFunctional(c.default || c))
export const DoctorShareProfile = () => import('../../components/DoctorShareProfile.vue' /* webpackChunkName: "components/doctor-share-profile" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage = () => import('../../components/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c))
export const FindDoctors = () => import('../../components/FindDoctors.vue' /* webpackChunkName: "components/find-doctors" */).then(c => wrapFunctional(c.default || c))
export const HealphantLogo = () => import('../../components/HealphantLogo.vue' /* webpackChunkName: "components/healphant-logo" */).then(c => wrapFunctional(c.default || c))
export const ImagePreview = () => import('../../components/ImagePreview.vue' /* webpackChunkName: "components/image-preview" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelector = () => import('../../components/LanguageSelector.vue' /* webpackChunkName: "components/language-selector" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const MyDoctorCard = () => import('../../components/MyDoctorCard.vue' /* webpackChunkName: "components/my-doctor-card" */).then(c => wrapFunctional(c.default || c))
export const NotificationBar = () => import('../../components/NotificationBar.vue' /* webpackChunkName: "components/notification-bar" */).then(c => wrapFunctional(c.default || c))
export const PasswordInput = () => import('../../components/PasswordInput.vue' /* webpackChunkName: "components/password-input" */).then(c => wrapFunctional(c.default || c))
export const PhoneInput = () => import('../../components/PhoneInput.vue' /* webpackChunkName: "components/phone-input" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalFiles = () => import('../../components/ProfessionalFiles.vue' /* webpackChunkName: "components/professional-files" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalLanguageEditor = () => import('../../components/ProfessionalLanguageEditor.vue' /* webpackChunkName: "components/professional-language-editor" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalWorkplace = () => import('../../components/ProfessionalWorkplace.vue' /* webpackChunkName: "components/professional-workplace" */).then(c => wrapFunctional(c.default || c))
export const SingleCase = () => import('../../components/SingleCase.vue' /* webpackChunkName: "components/single-case" */).then(c => wrapFunctional(c.default || c))
export const SinglePatient = () => import('../../components/SinglePatient.vue' /* webpackChunkName: "components/single-patient" */).then(c => wrapFunctional(c.default || c))
export const SinglePatientCase = () => import('../../components/SinglePatientCase.vue' /* webpackChunkName: "components/single-patient-case" */).then(c => wrapFunctional(c.default || c))
export const SplashScreen = () => import('../../components/SplashScreen.vue' /* webpackChunkName: "components/splash-screen" */).then(c => wrapFunctional(c.default || c))
export const Step = () => import('../../components/Step.vue' /* webpackChunkName: "components/step" */).then(c => wrapFunctional(c.default || c))
export const Stepper = () => import('../../components/Stepper.vue' /* webpackChunkName: "components/stepper" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = () => import('../../components/SvgIcon.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/Timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const TimerProgress = () => import('../../components/TimerProgress.vue' /* webpackChunkName: "components/timer-progress" */).then(c => wrapFunctional(c.default || c))
export const TopMenu = () => import('../../components/TopMenu.vue' /* webpackChunkName: "components/top-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../components/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const UserAvatarAndInfo = () => import('../../components/UserAvatarAndInfo.vue' /* webpackChunkName: "components/user-avatar-and-info" */).then(c => wrapFunctional(c.default || c))
export const UserDetails = () => import('../../components/UserDetails.vue' /* webpackChunkName: "components/user-details" */).then(c => wrapFunctional(c.default || c))
export const CustomerBottomNav = () => import('../../components/Customer/CustomerBottomNav.vue' /* webpackChunkName: "components/customer-bottom-nav" */).then(c => wrapFunctional(c.default || c))
export const CustomerTopNav = () => import('../../components/Customer/TopNav.vue' /* webpackChunkName: "components/customer-top-nav" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalBNav = () => import('../../components/Professional/BNav.vue' /* webpackChunkName: "components/professional-b-nav" */).then(c => wrapFunctional(c.default || c))
export const ChatAvatar = () => import('../../components/chat/Avatar.vue' /* webpackChunkName: "components/chat-avatar" */).then(c => wrapFunctional(c.default || c))
export const ChatCaseRating = () => import('../../components/chat/CaseRating.vue' /* webpackChunkName: "components/chat-case-rating" */).then(c => wrapFunctional(c.default || c))
export const ChatCloseCase = () => import('../../components/chat/CloseCase.vue' /* webpackChunkName: "components/chat-close-case" */).then(c => wrapFunctional(c.default || c))
export const ChatFeed = () => import('../../components/chat/Feed.vue' /* webpackChunkName: "components/chat-feed" */).then(c => wrapFunctional(c.default || c))
export const ChatFeedRequest = () => import('../../components/chat/FeedRequest.vue' /* webpackChunkName: "components/chat-feed-request" */).then(c => wrapFunctional(c.default || c))
export const ChatMenuChat = () => import('../../components/chat/MenuChat.vue' /* webpackChunkName: "components/chat-menu-chat" */).then(c => wrapFunctional(c.default || c))
export const ChatMessage = () => import('../../components/chat/Message.vue' /* webpackChunkName: "components/chat-message" */).then(c => wrapFunctional(c.default || c))
export const ChatMessageInput = () => import('../../components/chat/MessageInput.vue' /* webpackChunkName: "components/chat-message-input" */).then(c => wrapFunctional(c.default || c))
export const ChatReasonForRejection = () => import('../../components/chat/ReasonForRejection.vue' /* webpackChunkName: "components/chat-reason-for-rejection" */).then(c => wrapFunctional(c.default || c))
export const ChatVoiceMessageContent = () => import('../../components/chat/VoiceMessageContent.vue' /* webpackChunkName: "components/chat-voice-message-content" */).then(c => wrapFunctional(c.default || c))
export const ChatVoiceRecorder = () => import('../../components/chat/VoiceRecorder.vue' /* webpackChunkName: "components/chat-voice-recorder" */).then(c => wrapFunctional(c.default || c))
export const Chat = () => import('../../components/chat/index.vue' /* webpackChunkName: "components/chat" */).then(c => wrapFunctional(c.default || c))
export const DoctorDetailsIndex = () => import('../../components/doctor-details/DoctorDetailsIndex.vue' /* webpackChunkName: "components/doctor-details-index" */).then(c => wrapFunctional(c.default || c))
export const DoctorMoreInfo = () => import('../../components/doctor-details/DoctorMoreInfo.vue' /* webpackChunkName: "components/doctor-more-info" */).then(c => wrapFunctional(c.default || c))
export const DoctorPaymentDetails = () => import('../../components/doctor-details/DoctorPaymentDetails.vue' /* webpackChunkName: "components/doctor-payment-details" */).then(c => wrapFunctional(c.default || c))
export const DoctorPersonalDetails = () => import('../../components/doctor-details/DoctorPersonalDetails.vue' /* webpackChunkName: "components/doctor-personal-details" */).then(c => wrapFunctional(c.default || c))
export const DoctorVerificationInfo = () => import('../../components/doctor-details/DoctorVerificationInfo.vue' /* webpackChunkName: "components/doctor-verification-info" */).then(c => wrapFunctional(c.default || c))
export const DoctorDetailsProfessionalChargingFee = () => import('../../components/doctor-details/ProfessionalChargingFee.vue' /* webpackChunkName: "components/doctor-details-professional-charging-fee" */).then(c => wrapFunctional(c.default || c))
export const PaymentCardElement = () => import('../../components/payment/CardElement.vue' /* webpackChunkName: "components/payment-card-element" */).then(c => wrapFunctional(c.default || c))
export const PaymentCards = () => import('../../components/payment/Cards.vue' /* webpackChunkName: "components/payment-cards" */).then(c => wrapFunctional(c.default || c))
export const PaymentDetails = () => import('../../components/payment/PaymentDetails.vue' /* webpackChunkName: "components/payment-details" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
