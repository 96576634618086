import { Plugin } from "@nuxt/types"
import { Severity } from "@sentry/types"

import { AppPaths } from "@/enums"

declare module "axios" {
  interface AxiosResponse {
    errors?: {
      api: AxiosError["response"]
      fullError: AxiosError
    }
  }
}

const plugin: Plugin = ({ $axios, app, $sentry, redirect, route }) => {
  const PUBLIC_PATHS = [
    AppPaths.LOGIN,
    AppPaths.USER_DETAILS,
    AppPaths.FIND_PROFESSIONAL,
  ] as String[]

  $axios.onRequest((config) => {
    const authorization = app.$cookies.get(app.$enums.Cookies.AUTHORIZATION)

    if (authorization) {
      config.headers.authorization = authorization
    }
  })

  $axios.onError((error) => {
    const api = error.response
    const errors = { api, fullError: error }
    const redirectPath = (
      process.client ? window.location.pathname : route.path
    ).replace(`/^\\/${app.i18n.locale}\\//`, "/")

    if (
      error.response?.status === 401 &&
      !PUBLIC_PATHS.includes(redirectPath)
    ) {
      app.$accessor.user.clearSession()
      redirect({
        path: app.localePath(app.$enums.AppPaths.LOGIN),
        query: { redirectPath },
      })
    } else if (error.response?.status !== 401) {
      const { config, response } = error

      $sentry.captureEvent({
        message: errors.api?.data.message || error.message,
        level: Severity.Critical,
        extra: {
          request: {
            baseURL: config.baseURL,
            url: config.url,
            method: config.method,
            headers: config.headers
              ? JSON.stringify(config.headers)
              : undefined,
            params: config.params ? JSON.stringify(config.params) : undefined,
            data: config.data ? JSON.stringify(config.data) : undefined,
          },
          response: {
            status: response?.status
              ? JSON.stringify(response.status)
              : undefined,
            headers: response?.headers
              ? JSON.stringify(response.headers)
              : undefined,
            data: response?.data ? JSON.stringify(response.data) : undefined,
          },
        },
      })
    }
    return Promise.reject(error)
  })
}
export default plugin
