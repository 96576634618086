export enum AppPaths {
  HOME = "/",
  SELECT_DOCTOR = "/select-doctor",
  LOGIN = "/login",
  LOGOUT = "/logout",
  SIGN_UP = "/sign-up",
  YOU_ARE_IN = "/you-are-in",
  SELECT_ROLE = "/select-role",
  USER_DETAILS = "/user-details",
  RESET_PASSWORD_REQUEST = "/reset-password/request",
  RESET_PASSWORD = "/reset-password",
  PROFESSIONAL_PROFILE = "/doctor/profile",
  MY_CASES = "/doctor/my-cases",
  MY_PATIENTS = "/doctor/patients",
  MY_PATIENT_DETAILS = "/doctor/patients/:id",
  PROFESSIONALS = "/patient/doctors",
  PROFESSIONAL_CONTACT = "/patient/doctors/:id",
  FIND_PROFESSIONAL = "/patient/doctors/",
  CHAT = "/chat/:id",
  CHAT_PAYMENT = "/chat/:id/payment",
  CUSTOMER_PROFILE = "/patient/profile",
  CUSTOMER_CASES = "/patient/my-cases",
  PROFESSIONAL = "/doctor",
  CUSTOMER = "/patient",
  CHAT_INDEX = "/chat",
}
