import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_217e657c from 'nuxt_plugin_plugin_217e657c' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_42bef856 from 'nuxt_plugin_sentryserver_42bef856' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_45f4f264 from 'nuxt_plugin_sentryclient_45f4f264' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_bootstrapvue_77e616c1 from 'nuxt_plugin_bootstrapvue_77e616c1' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_plugin_75c7d164 from 'nuxt_plugin_plugin_75c7d164' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_clipboard_7f0934f2 from 'nuxt_plugin_clipboard_7f0934f2' // Source: ./clipboard.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_a0d3b38c from 'nuxt_plugin_cookieuniversalnuxt_a0d3b38c' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_4cbab9e9 from 'nuxt_plugin_pluginutils_4cbab9e9' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_82171e18 from 'nuxt_plugin_pluginrouting_82171e18' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_094f3efb from 'nuxt_plugin_pluginmain_094f3efb' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_workbox_ff5ff9a4 from 'nuxt_plugin_workbox_ff5ff9a4' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_63423c41 from 'nuxt_plugin_metaplugin_63423c41' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_67e9e1b5 from 'nuxt_plugin_iconplugin_67e9e1b5' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_72cfea92 from 'nuxt_plugin_axios_72cfea92' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_onesignal_360756fa from 'nuxt_plugin_onesignal_360756fa' // Source: ./onesignal.js (mode: 'client')
import nuxt_plugin_nuxttypedvuex_5be14ce9 from 'nuxt_plugin_nuxttypedvuex_5be14ce9' // Source: ./nuxt-typed-vuex.js (mode: 'all')
import nuxt_plugin_api_caeae0b4 from 'nuxt_plugin_api_caeae0b4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_axios_2228ef02 from 'nuxt_plugin_axios_2228ef02' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_enums_225cee1e from 'nuxt_plugin_enums_225cee1e' // Source: ../plugins/enums (mode: 'all')
import nuxt_plugin_dropdowndatepicker_9ef9c2f8 from 'nuxt_plugin_dropdowndatepicker_9ef9c2f8' // Source: ../plugins/dropdown-datepicker (mode: 'all')
import nuxt_plugin_providers_7fea752e from 'nuxt_plugin_providers_7fea752e' // Source: ../plugins/providers (mode: 'all')
import nuxt_plugin_vuefragment_75145aab from 'nuxt_plugin_vuefragment_75145aab' // Source: ../plugins/vue-fragment (mode: 'all')
import nuxt_plugin_veevalidate_346f6561 from 'nuxt_plugin_veevalidate_346f6561' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_i18n_926bd3dc from 'nuxt_plugin_i18n_926bd3dc' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_qrcode_3deb3282 from 'nuxt_plugin_qrcode_3deb3282' // Source: ../plugins/qrcode (mode: 'all')
import nuxt_plugin_vuegtag_15ca6fae from 'nuxt_plugin_vuegtag_15ca6fae' // Source: ../plugins/vue-gtag (mode: 'all')
import nuxt_plugin_googleAnalytics_21f270d9 from 'nuxt_plugin_googleAnalytics_21f270d9' // Source: ../plugins/googleAnalytics (mode: 'all')
import nuxt_plugin_nuxtClientInit_5086bfd4 from 'nuxt_plugin_nuxtClientInit_5086bfd4' // Source: ../plugins/nuxtClientInit (mode: 'all')
import nuxt_plugin_vClickOutside_6739adc2 from 'nuxt_plugin_vClickOutside_6739adc2' // Source: ../plugins/vClickOutside (mode: 'client')
import nuxt_plugin_auth_5a03245e from 'nuxt_plugin_auth_5a03245e' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_meta_25069514 from 'nuxt_plugin_meta_25069514' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"yogacancer","title":"%s","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Health on a chat"},{"property":"og:site_name","content":"yogacancer"},{"property":"og:locale","content":"en_GB"},{"property":"og:type","content":"website"},{"property":"og:image:width","content":"200"},{"property":"og:image:height","content":"200"},{"property":"og:image","content":"https:\u002F\u002Fyogacancer.com\u002Fwp-content\u002Fuploads\u002F2020\u002F11\u002Ffb-1.jpg"},{"property":"og:title","content":"Yogacancer"},{"property":"og:url","content":"https:\u002F\u002Fwww.yogacancer.com"},{"property":"og:description","content":"Yogacancer Union"},{"name":"google-site-verification","content":"2L6_YDTYz5bWzu7zr3oWNL52YI3U9dJpNw5d0D28hM0"}],"link":[{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Lemon&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ficon.png"}],"style":[],"script":[{"async":true,"src":"https:\u002F\u002Fcdn.onesignal.com\u002Fsdks\u002FOneSignalSDK.js","hid":"onesignal"}]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_217e657c === 'function') {
    await nuxt_plugin_plugin_217e657c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_42bef856 === 'function') {
    await nuxt_plugin_sentryserver_42bef856(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_45f4f264 === 'function') {
    await nuxt_plugin_sentryclient_45f4f264(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrapvue_77e616c1 === 'function') {
    await nuxt_plugin_bootstrapvue_77e616c1(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_75c7d164 === 'function') {
    await nuxt_plugin_plugin_75c7d164(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clipboard_7f0934f2 === 'function') {
    await nuxt_plugin_clipboard_7f0934f2(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_a0d3b38c === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_a0d3b38c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_4cbab9e9 === 'function') {
    await nuxt_plugin_pluginutils_4cbab9e9(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_82171e18 === 'function') {
    await nuxt_plugin_pluginrouting_82171e18(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_094f3efb === 'function') {
    await nuxt_plugin_pluginmain_094f3efb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_ff5ff9a4 === 'function') {
    await nuxt_plugin_workbox_ff5ff9a4(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_63423c41 === 'function') {
    await nuxt_plugin_metaplugin_63423c41(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_67e9e1b5 === 'function') {
    await nuxt_plugin_iconplugin_67e9e1b5(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_72cfea92 === 'function') {
    await nuxt_plugin_axios_72cfea92(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_onesignal_360756fa === 'function') {
    await nuxt_plugin_onesignal_360756fa(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxttypedvuex_5be14ce9 === 'function') {
    await nuxt_plugin_nuxttypedvuex_5be14ce9(app.context, inject)
  }

  if (typeof nuxt_plugin_api_caeae0b4 === 'function') {
    await nuxt_plugin_api_caeae0b4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2228ef02 === 'function') {
    await nuxt_plugin_axios_2228ef02(app.context, inject)
  }

  if (typeof nuxt_plugin_enums_225cee1e === 'function') {
    await nuxt_plugin_enums_225cee1e(app.context, inject)
  }

  if (typeof nuxt_plugin_dropdowndatepicker_9ef9c2f8 === 'function') {
    await nuxt_plugin_dropdowndatepicker_9ef9c2f8(app.context, inject)
  }

  if (typeof nuxt_plugin_providers_7fea752e === 'function') {
    await nuxt_plugin_providers_7fea752e(app.context, inject)
  }

  if (typeof nuxt_plugin_vuefragment_75145aab === 'function') {
    await nuxt_plugin_vuefragment_75145aab(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_346f6561 === 'function') {
    await nuxt_plugin_veevalidate_346f6561(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_926bd3dc === 'function') {
    await nuxt_plugin_i18n_926bd3dc(app.context, inject)
  }

  if (typeof nuxt_plugin_qrcode_3deb3282 === 'function') {
    await nuxt_plugin_qrcode_3deb3282(app.context, inject)
  }

  if (typeof nuxt_plugin_vuegtag_15ca6fae === 'function') {
    await nuxt_plugin_vuegtag_15ca6fae(app.context, inject)
  }

  if (typeof nuxt_plugin_googleAnalytics_21f270d9 === 'function') {
    await nuxt_plugin_googleAnalytics_21f270d9(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtClientInit_5086bfd4 === 'function') {
    await nuxt_plugin_nuxtClientInit_5086bfd4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vClickOutside_6739adc2 === 'function') {
    await nuxt_plugin_vClickOutside_6739adc2(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_5a03245e === 'function') {
    await nuxt_plugin_auth_5a03245e(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_25069514 === 'function') {
    await nuxt_plugin_meta_25069514(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
