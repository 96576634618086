import { AxiosResponse } from "axios"
import { actionTree, getterTree, mutationTree } from "typed-vuex"

import { UserStatus } from "@/enums"
import { UpdateUserPayload } from "@/types/apiPayloads/UpdateUserPayload"
import { User } from "@/types/models/User"

interface UserState {
  user: User | undefined
  hasUser: boolean
  avatar: string
}
export const state = (): UserState => ({
  user: undefined,
  hasUser: false,
  avatar: "",
})

export const getters = getterTree(state, {
  currentUser: (state: UserState) => state.user,
  hasUser: (state: UserState) => state.hasUser,
  isEmailVerified: (state: UserState) =>
    state.user?.status === UserStatus.EMAIL_VERIFIED,
  avatar: (state) => state.avatar,
  currentUserRole: (state) => state.user?.selectedRole,
  isProfessionalVerified: (state) =>
    state.user?.status === UserStatus.PROFESSIONAL_VERIFIED,
})

export const mutations = mutationTree(state, {
  SET_CURRENT_USER: (state, user: User | undefined) => {
    state.user = user
    state.hasUser = Boolean(user)
  },
  SET_CURRENT_USER_AVATAR: (state, avatar: string) => {
    state.avatar = avatar
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async signup(
      _,
      { user, password }: { user: Partial<User>; password: string },
    ): Promise<AxiosResponse<User>> {
      const response = await this.app.$api.user.create(user, password)
      this.app.$accessor.user.setSession({
        user: response.data,
        token: response.headers?.authorization,
      })
      return response
    },
    async login(
      _,
      { email, password }: { email: string; password: string },
    ): Promise<AxiosResponse<User>> {
      const response = await this.$api.user.logIn(email, password)
      const { data, headers } = response
      this.app.$accessor.user.setSession({
        user: data,
        token: headers?.authorization,
      })

      return response
    },
    async resetPassword(
      _,
      {
        email,
        password,
        resetPasswordToken,
      }: { email: string; password: string; resetPasswordToken: string },
    ): Promise<AxiosResponse<User>> {
      const response = await this.$api.user.resetPassword(
        email,
        password,
        resetPasswordToken,
      )
      const { data, headers } = response
      this.app.$accessor.user.setSession({
        user: data,
        token: headers?.authorization,
      })

      return response
    },
    async logout(): Promise<void> {
      const currentUser = this.app.$accessor.user.currentUser
      if (currentUser) {
        try {
          await this.app.$api.user.logOut()
        } catch {
          // if any error happens here, do nothing
        } finally {
          this.app.$accessor.user.clearSession()
        }
      }
    },
    setSession(
      { commit },
      { user, token }: { user: User; token: string },
    ): void {
      if (token) {
        this.app.$accessor.user.setToken({ token })
        commit("SET_CURRENT_USER", user)
        this.app.$accessor.user.setUserPreferences()
      }
    },
    setToken(_, { token }: { token: string }): void {
      if (token) {
        const today = new Date()
        today.setMonth(today.getMonth() + 1)

        this.app.$cookies.set(this.$enums.Cookies.AUTHORIZATION, token, {
          expires: today,
          secure: this.app.$config.ENV !== "development",
          sameSite: "lax",
          path: "/",
        })
      }
    },
    clearSession(): void {
      this.app.$accessor.user.setCurrentUser({ user: undefined })
      this.app.$cookies.remove(this.$enums.Cookies.AUTHORIZATION)
    },
    setCurrentUser({ commit }, { user }: { user: User | undefined }): void {
      commit("SET_CURRENT_USER", user)
      if (user !== undefined) this.app.$accessor.user.setUserPreferences()
    },
    setUserPreferences({ state }): void {
      if (state.user) {
        this.$i18n.setLocale(state.user.selectedLocale)
      } else {
        this.app.$accessor.user.setDefaultPreferences()
      }
    },
    setDefaultPreferences(): void {
      this.$i18n.setLocale(this.$enums.LocaleCodes.EN_GB)
    },

    async createProfessional(_): Promise<boolean> {
      let success = true
      const apiCall = await this.$api.user.createProfessional()

      if (apiCall.status !== 200) {
        success = false
      }

      this.app.$accessor.user.setSession({
        user: apiCall.data,
        token: apiCall.headers.authorization,
      })

      return success
    },
    async fetchCurrentUser({ commit }) {
      const authorization = Boolean(
        this.app.$cookies.get(this.app.$enums.Cookies.AUTHORIZATION),
      )

      if (authorization) {
        try {
          const { data } = await this.$api.user.me()
          commit("SET_CURRENT_USER", data)
        } catch {
          this.app.$accessor.user.clearSession()
        }
      }
    },
    async createCustomer({ state }): Promise<void> {
      if (state.user) {
        const response = await this.$api.customer.create()
        const { data } = response
        this.app.$accessor.user.setCurrentUser({
          user: data,
        })
      }
    },
    async updateUser(
      { commit },
      {
        data,
      }: {
        data: {
          [key in keyof UpdateUserPayload]?: Partial<UpdateUserPayload[key]>
        }
      },
    ) {
      const response = await this.$api.user.updateUser({
        data: { ...data },
      })

      return commit("SET_CURRENT_USER", response.data)
    },
  },
)
