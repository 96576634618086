export enum ApiPaths {
  CREATE_USER = "/v2/user/create",
  CREATE_CUSTOMER = "/v2/customer",
  CHECK_EMAIL_AVAILABILITY = "/v2/user/email/availability",
  VERIFY_EMAIL = "/v2/user/email/verify",
  RESEND_VERIFY_EMAIL = "/v2/user/email/resend/link",
  LOG_IN = "/v2/user/login",
  SOCIAL_LOG_IN = "/v2/user/login/social",
  ADD_DEVICE_PUSH = "/v2/user/push-notification/device/add",
  LOG_OUT = "/v2/user/logout",
  GET_USER = "/v2/user",
  GET_USER_ME = "/v2/user/me",
  INSERT_SIGNUP_DATA = "/v2/user/signup-data",
  INSERT_SIGNUP_DATA_FILES = "/v2/user/signup-data/files",
  RESET_PASSWORD = "/v2/user/reset-password/",
  REQUEST_TOKEN_RESET_PASSWORD = "/v2/user/reset-password/request",
  UPDATE_USER = "/v2/user/update",
  UPDATE_PROFESSIONAL_DATA = "/v2/professional/update",
  GET_SIGNUP_DATA = "v2/user/signup-data",
  GET_SPECIALTIES = "/v2/static-data/speciality",
  GET_LANGUAGES = "/v2/static-data/language",
  GET_LOCALES = "/v2/static-data/locale",
  GET_COUNTRIES = "/v2/static-data/country",
  GET_PROFESSIONAL_LANGUAGES = "/v2/professional/language",
  GET_PROFESSIONAL_SPECIALITY_TRANSLATION = "/v2/professional/speciality/translation",
  GET_SPECIALITY_TRANSLATION = "/v2/static-data/speciality/translation",
  GET_GENDERS = "/v2/static-data/gender",
  GET_CURRENCY_FEES = "/v2/static-data/currency/fees",
  CREATE_PROFESSIONAL = "/v2/professional",
  CASE_MESSAGE = "/v2/case-message",
  CASE_MESSAGE_ATTACHMENT = "/v2/case-message/attachments/download",
  CASE_VOICE_MESSAGE = "/v2/case-message/voice",
  CASE_VIDEO_MESSAGE = "/v2/case-message/video",
  CASE = "/v2/case",
  CHAT_REQUEST_ACCEPT = "/v2/case/accept",
  CHAT_REQUEST_REJECT = "/v2/case/reject",
  GET_PROFESSIONAL = "/v2/professional/get",
  GET_CUSTOMER_PROFESSIONALS = "/v2/customer/my-professionals",
  CLOSE_CASE = "/v2/case/close",
  CASE_REVIEW = "/v2/case/review",
  PAYMENT_CASE_UPDATE = "/v2/case/:id/payment",
  PAYMENT_INTENT = "/v2/case/payment/intent",
  PAYMENT_CARDS = "/v2/case/payment/methods",
  PAYMENT_ATTACH_CARDS = "/v2/case/payment/method/attach",
  PAYMENT_DETACH_CARDS = "/v2/case/payment/method/detach",
  CASE_REJECT = "/v2/case/reject",
  GET_PROFESSIONAL_CASES = "/v2/case/professional",
  PROFESSIONAL_SHARE_LINK = "/v2/professional/share-link",
  GET_CUSTOMER_CASES = "/v2/case/customer",
  GET_AREAS_OF_INTEREST = "/v2/professional/interest-areas",
  GET_PROFESSIONAL_CUSTOMERS = "/v2/professional/my-customers",
  GET_CASES_WITH_USERS = "/v2/case/with-users",
  UPDATE_USER_FILES = "/v2/user/update/files",
  GET_USER_FILES = "/v2/user/files",
  GET_CASE_PARTNER = "/v2/partner/voucher",
  GET_CASE_STATUS = "/v2/case/status",
  GET_CASE_STATUS_HISTORY = "/v2/case/status-history",
  GET_ALL_PARTNERS = "/v2/partner/get-all",
  GET_GEOLOCATIONS = "/v2/geolocations/get",
  GET_GEOLOCATIONS_AUTOCOMPLETE = "/v2/geolocations/autocomplete",
  GET_WORKPLACES_AUTOCOMPLETE = "/v2/geolocations/workplaces/autocomplete",
  GET_AVATAR = `/avatars/rs:auto:WIDTH:HEIGHT/plain/:userId.jpg`,
  POST_TRACKED_EVENT = "/v2/tracked-events",
}
