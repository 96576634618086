import { Plugin } from "@nuxt/types"
import { registerLocale } from "@cospired/i18n-iso-languages"
import { localize } from "vee-validate"

import { LocaleCodes } from "@/enums"
import { validation as enGB } from "@/locales/en-GB.json"
import { validation as frFR } from "@/locales/fr-FR.json"

const i18n: Plugin = ({ app }) => {
  registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"))
  registerLocale(require("@cospired/i18n-iso-languages/langs/fr.json"))

  const dictionary: Record<string, object> = {
    [LocaleCodes.FR_FR]: {
      name: LocaleCodes.FR_FR,
      messages: frFR,
    },
    [LocaleCodes.EN_GB]: {
      name: LocaleCodes.EN_GB,
      messages: enGB,
    },
  }

  localize(app.i18n.locale, dictionary[app.i18n.locale])
  app.i18n.onLanguageSwitched = (_, newLocale) => {
    localize(newLocale, dictionary[newLocale])
  }
}

export default i18n
